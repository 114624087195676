import React, { useEffect, useState, useRef } from 'react';
import { RobotItem } from '../models/models';
import { ReactComponent as ChevronDown } from '../assets/chevron-down.svg';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { ReactComponent as UmanLogoIcon } from '../assets/uman-ai-logo.svg';
import { ReactComponent as UmanIcon } from '../assets/uman-ai.svg';
import { ReactComponent as LibraryIcon } from '../assets/use-case-library.svg';
import { ReactComponent as PersonIcon } from '../assets/person.svg';

interface Props {
  item: RobotItem;
  index: number;
  color: string;
  activeId: number;
  experts: Set<string>;
}

const InfoItem: React.FC<Props> = ({
  color,
  item,
  index,
  activeId,
  experts = new Set(),
}) => {
  const nodeRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExperts, setIsOpenExperts] = useState(false);
  useEffect(() => {
    if (activeId === item.id) {
      if (!isOpen) {
        setIsOpen(true);
      }
    } else {
      if (activeId !== 0) setIsOpen(false);
    }
  }, [activeId, isOpen, item.id]);

  return (
    <div className={clsx('item', isOpen && 'item__active')}>
      <div className="item-button" onClick={() => setIsOpen(!isOpen)}>
        <div className="item-main">
          <div
            className="item-id"
            style={{
              backgroundColor: color,
              fontSize: index.toString().length === 1 ? '12px' : '11px',
            }}
          >
            {index}
          </div>
          <p className="item-name">{item.label}</p>
        </div>

        <ChevronDown className={clsx('item-icon', isOpen && 'item-icon__active')} />
      </div>

      <CSSTransition
        timeout={250}
        in={isOpen}
        unmountOnExit
        mountOnEnter
        classNames="anim-item"
        nodeRef={nodeRef}
      >
        <div className="item-description">
          <ReactMarkdown
            plugins={[gfm]}
            children={item.description ? item.description : 'No info available'}
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://app.uman.ai/search/a6249e06-b9b7-42ac-ae6d-ac05cb8cf26a?search=${item.label}`}
          >
            <div className="info-footer">
              <UmanIcon className="url-icon" />
              <UmanLogoIcon className="uman-logo-icon" />
            </div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://use-cases.internal.ml6.eu/library?vertical=all+chapter=all+offer-state=Accepted+offer-state=Draft+offer-state=Wait+comp=${item.label}+slider_min=0+slider_max=999+`}
          >
            <div className="info-footer">
              <LibraryIcon className="url-icon" />
              <p className="info-url-name">Use Case Library</p>
            </div>
          </a>
          <div onClick={() => setIsOpenExperts(!isOpenExperts)}>
            <div className="info-footer">
              <PersonIcon className="url-icon" />
              <p className="info-url-name">Experts</p>
            </div>
            <CSSTransition
              timeout={250}
              in={isOpenExperts && experts.size !== 0}
              unmountOnExit
              mountOnEnter
              classNames="anim-item"
              nodeRef={nodeRef}
            >
              <div>
                <ul>
                  {Array.from(experts).map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            </CSSTransition>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default InfoItem;
