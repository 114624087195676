import React, { useEffect, useState } from 'react';
import './App.css';
import RadarDisplay from './components/RadarDisplay';

import { db, auth, provider } from './firebase';

function App() {
  // Hook for user login and user data
  useEffect(() => {
    auth.onAuthStateChanged(() => {
      if (!auth.currentUser) {
        auth.getRedirectResult().then((result) => {
          if (result.user) {
            auth.updateCurrentUser(result.user);
          } else {
            auth.signInWithRedirect(provider);
          }
        });
      }
    });
  }, []);

  // Hook to read experts
  const [expertComponents, setExpertComponents] = useState({});
  useEffect(() => {
    let isSubscribed = true;
    db.collection(process.env.REACT_APP_PEOPLE_WITH_COMPONENTS_COLLECTION)
      .get()
      .then((snapshot) => {
        if (isSubscribed) {
          let expertComponentsDict = {};
          snapshot.forEach((doc) => {
            doc.data().components.forEach((item) => {
              expertComponentsDict[item.name] =
                expertComponentsDict[item.name] || new Set();
              expertComponentsDict[item.name].add(doc.data().name);
            });
          });
          setExpertComponents(expertComponentsDict);
        }
      })
      .catch((error) => console.error('Error reading experts data: ', error));
    return () => (isSubscribed = false);
  }, []);

  return (
    (auth.currentUser && (
      <div className="App">
        <div className="container">
          <RadarDisplay expertComponents={expertComponents} />
        </div>
      </div>
    )) || <div className="loader"></div>
  );
}

export default App;
