import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCuvbD_jYanD8ISqBEMZIHzowPPURZhltU',
  authDomain: 'ml6-internal-tools-c970e.firebaseapp.com',
  databaseURL: 'https://ml6-internal-tools-c970e.firebaseio.com',
  projectId: 'ml6-internal-tools',
  storageBucket: 'ml6-internal-tools.appspot.com',
  messagingSenderId: '916172072576',
  appId: '1:916172072576:web:b4370aaae5089e977fa865',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();

const provider = new firebase.auth.GoogleAuthProvider();

export { db, auth, provider };
