import React, { useEffect, useRef, useState } from 'react';
import InfoItem from './InfoItem';
import { Config, RobotItem } from '../models/models';
import { ReactComponent as Logo } from '../assets/logo.svg';
import Fuse from 'fuse.js';

interface Props {
  config: Config;
  data: RobotItem[];
  activeId: number;
  expertComponents: { [Key: number]: Set<string> };
}

const options = {
  threshold: 0.4,
  keys: ['description', 'label'],
};

const RadarInfo: React.FC<Props> = ({ activeId, config, data, expertComponents }) => {
  const ref = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [fuseData, setFuseData] = useState<any>(data);

  const fuse = new Fuse(data, options);

  const handleMatches = (input) => {
    if (input === '') {
      setFuseData(data);
    } else {
      setFuseData(fuse.search(input).map((res) => res.item) || []);
    }
    setSearchInput(input);
  };

  useEffect(() => {
    if (activeId) {
      setFuseData(data);
      setSearchInput('');
    }
    if (ref.current && activeId) {
      const element = ref.current as Element;
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeId, data]);

  return (
    <div className="sidebar">
      <div className="header">
        <Logo className="logo" />
        <h1 className="title">{config.title}</h1>
        <input
          placeholder="Search"
          className="search"
          value={searchInput}
          onChange={(e) => handleMatches(e.target.value)}
          type="text"
        />
      </div>
      <div className="info-list">
        {fuseData.length === 0 && <h3 className="quadrant-name">No results</h3>}
        {config.quadrants.map((qd) => {
          if (fuseData.filter((item) => item.quadrant === qd.id).length !== 0) {
            return (
              <div key={qd.name} className="quadrant">
                <h3 className="quadrant-name">{qd.name}</h3>

                <div>
                  {config.rings.map((ring) => {
                    const filter = fuseData.filter((item) => item.ring === ring.id);
                    if (filter.length !== 0) {
                      if (filter.filter((item) => item.quadrant === qd.id).length !== 0) {
                        return (
                          <div key={ring.name}>
                            <h4 className="ring-name">{ring.name}</h4>
                            {fuseData &&
                              fuseData.map((item) => {
                                if (item.ring === ring.id && item.quadrant === qd.id) {
                                  return (
                                    <div
                                      key={item.label}
                                      ref={
                                        item.id.toString() === activeId.toString()
                                          ? ref
                                          : null
                                      }
                                    >
                                      <InfoItem
                                        activeId={activeId}
                                        color={qd.color}
                                        item={item}
                                        index={item.id}
                                        experts={expertComponents[item.label]}
                                      />
                                    </div>
                                  );
                                }
                                return null;
                              })}
                          </div>
                        );
                      } else return <div key={ring.name} />;
                    } else return <div />;
                  })}
                </div>
              </div>
            );
          } else {
            return <div />;
          }
        })}
      </div>
    </div>
  );
};

export default RadarInfo;
