import React, { useCallback, useEffect, useState } from 'react';
import RadarInfo from './RadarInfo';
import { Config, RobotItem } from '../models/models';
import Radar from './Radar';

const CACHE_TTL = 10;
const GOOGLE_SPREADSHEET_LINK =
  'https://docs.google.com/spreadsheets/d/1ZTF2LZgjaTx5kEYkxEQvoCSqoqRsjIa2rJFus4OvxmU/edit?usp=sharing';

let hasRun = false;

interface Props {
  expertComponents: { [Key: number]: Set<string> };
}

const RadarDisplay: React.FC<Props> = ({ expertComponents }) => {
  const [robotData, setRobotData] = useState<RobotItem[]>([]);
  const [configData, setConfigData] = useState<any>({});
  const [config, setConfig] = useState(null);
  const [activeId, setActiveId] = useState(0);

  const getZRadarConfig = useCallback(
    (
      quadrants: { name: string; id: number; color: string }[],
      rings: { name: string; id: number }[],
      title: string
    ) => {
      const config: Config = {
        width: 1000,
        height: 910,
        colors: {
          background: 'transparent',
          grid: '#bababa',
          inactive: '#bbb',
        },
        title: title ? title : 'ML6 Radar',
        quadrants: quadrants,
        rings: rings,
        entries: [],
      };

      robotData.sort((a, b) => {
        const qdA = quadrants.findIndex((i) => i.id === a.quadrant);
        const qdB = quadrants.findIndex((i) => i.id === b.quadrant);

        const ringA = config.rings.find((i) => i.id === a.ring).id;
        const ringB = config.rings.find((i) => i.id === b.ring).id;

        return qdA - qdB || ringA - ringB;
      });

      robotData.forEach((dataItem, index) => {
        dataItem.id = index + 1;
        config.entries.push(dataItem);
      });

      setConfig(config);
    },
    [robotData]
  );

  useEffect(() => {
    if (configData && Object.keys(configData).length > 0 && !hasRun) {
      const qdList: { name: string; id: number; color: string }[] = [];
      const ringList: { name: string; id: number }[] = [];
      let title = configData['title'];
      configData['circles'].forEach((item) => {
        ringList.push({
          id: parseInt(item.circle_id),
          name: item.circle_name,
        });
      });
      configData['quadrants'].forEach((item) => {
        qdList.push({
          id: parseInt(item.quadrant_id),
          name: item.quadrant_name,
          color: item.quadrant_color,
        });
      });
      getZRadarConfig(qdList, ringList, title);
      hasRun = true;
    }
  }, [getZRadarConfig, configData]);

  useEffect(() => {
    const getDataFromCache = (cacheKey) => {
      const scheduleInvalidate = (timeToLive) => {
        setTimeout(() => {
          localStorage.removeItem(`RADAR_DATA_${GOOGLE_SPREADSHEET_LINK}`);
        }, timeToLive * 1000);
      };

      const getFromCloudFunction = async () => {
        const response = await fetch(
          'https://europe-west1-ml6-internal-tools.cloudfunctions.net/radar_get_data?key=HXyefX2cCWShIeoWY46YiUxuRU81maaxLyEhHGvXXN'
        );
        const data = await response.json();

        const robotData = data['data'];
        const configData = data['config'];

        robotData.map((element) => {
          return {
            ...element,
            quadrant: parseInt(element.quadrant),
            ring: parseInt(element.ring),
            moved: parseInt(element.moved),
          };
        });
        setRobotData(robotData);
        setConfigData(configData);
        localStorage.setItem(
          `RADAR_DATA_${GOOGLE_SPREADSHEET_LINK}`,
          JSON.stringify({ robot: robotData, config: configData })
        );
        scheduleInvalidate(CACHE_TTL);
      };

      const cachedData = localStorage.getItem(`RADAR_DATA_${GOOGLE_SPREADSHEET_LINK}`);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setRobotData(parsedData.robot);
        setConfigData(parsedData.config);
        scheduleInvalidate(CACHE_TTL);
      } else {
        getFromCloudFunction();
      }
    };

    getDataFromCache(GOOGLE_SPREADSHEET_LINK);
  }, []);

  return (
    <div className="wrapper">
      {config && (
        <>
          <RadarInfo
            activeId={activeId}
            config={config}
            data={robotData}
            expertComponents={expertComponents}
          />
          <div className="radar">
            <Radar setActiveId={(id) => setActiveId(id)} config={config} />
          </div>
        </>
      )}
    </div>
  );
};

export default RadarDisplay;
